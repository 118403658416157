import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { DateTime } from 'luxon'
import { makeStyles, ListItem, List, Grid, Divider } from '@material-ui/core'
import { questionArray } from '../utils/cact-scoring'
import CACTLevels from '../utils/CACTLevels'
import { parseCACTItem } from '../utils/parseCACTData'
import getNameWithPossessiveApostrophe from '../utils/getNameWithPossessiveApostrophe'
import { ImpactContext } from '../components/Layout'
import { CardCollapse, CircularProgress, Modal, ReportChart } from '../components/library'
import CACTScale from '../components/CACTScale'

const useStyles = makeStyles({
  questionListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    lineHeight: '19px',
    paddingLeft: 0
  },
  questionChoice: {
    paddingLeft: '10px'
  }
})

const CACTDetailViewPage = ({ location }) => {
  const { userData, profiles } = useContext(ImpactContext)
  const classes = useStyles()
  let response = typeof location !== 'undefined' && location && location.state ? location.state.response : null
  let backTo = typeof location !== 'undefined' && location && location.state?.backTo ? location.state.backTo : -1

  if (!response || !profiles) return <CircularProgress />
  const mapListItems = (question, i: number, arr: Array) => {
    const answerText = question.labels[response.attributes[question.field]]
    return (
      <>
        <ListItem className={classes.questionListItem}>
          <Grid container>
            <Grid xs={8} item>
              {question.label}
            </Grid>
            <Grid xs={4} className={classes.questionChoice} item>
              {answerText}
            </Grid>
          </Grid>
        </ListItem>
        {i !== arr.length - 1 ? <Divider /> : ''}
      </>
    )
  }

  const childList = questionArray.slice(0, 4).map(mapListItems)
  const parentList = questionArray.slice(4, 7).map(mapListItems)
  const dt = DateTime.fromISO(response.attributes.field_test_date_iso)
  const chart_data = [parseCACTItem(response)]

  return (
    <Modal
      id="cact-detail-modal"
      open={true}
      title="C-ACT Result"
      subtitle={`${dt.toLocaleString(DateTime.DATE_FULL)} ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`}
      handleClose={e => {
        navigate(backTo)
      }}
    >
      <ReportChart
        id="c-act-detail-view"
        data={chart_data}
        unit="integer"
        levels={CACTLevels}
        max={27}
        defaultMode="week"
        hideEmptyDays
        hideTopbar
        chartType="BarChart"
        disableInteractivity
        relativeDate={dt}
      />
      <CACTScale hideBar={true} />
      <CardCollapse profile={profiles.child} title={`${getNameWithPossessiveApostrophe(profiles.child)} Responses`}>
        <List>{childList}</List>
      </CardCollapse>
      <CardCollapse profile={profiles.parent} title={`${getNameWithPossessiveApostrophe(profiles.parent)} Responses`}>
        <List>{parentList}</List>
      </CardCollapse>
    </Modal>
  )
}

export default CACTDetailViewPage
